(function ($) {
  Drupal.behaviors.elcPopularSearchV1 = {
    attached: false,
    attach: function (context) {
      var $trendingTerm = $('.js-esearch-nav__popular-list', context);
      var $analyticalTag = $('.js_popular_search_analytical_tag', $trendingTerm);
      var $popularSearchLink = $('.js_popular_search__link');
      var analyticalTagValue = $analyticalTag.val();

      $popularSearchLink.each(function () {
        var $currentURL = $(this).attr('href');
        var $queryParameter = typeof $currentURL !== 'undefined' && $currentURL.length > 0 && $currentURL.indexOf("?") >= 0 ? '&' : '?';
        var $newURL = $currentURL + $queryParameter + 'search_type=' + analyticalTagValue;

        if (typeof analyticalTagValue !== 'undefined' && analyticalTagValue.length > 0) {
          $(this).attr('href', $newURL);
        }
      });
    }
  };
})(jQuery, Drupal.settings);
